<template>
  <v-container>
    <div class="text-h3 mt-4">Næringskalkulator for Matvarer</div>
    <div class="text-h6 mb-4">Beregn nøyaktig næringsinnholdet av alle ingrediensene i Matkurven din!</div>
    <v-card v-if="$store.getters.searchText.length == 0" flat>
    <v-divider></v-divider>
    <v-card-text class="text-h6 my-2 px-0">Du har ikke lagt til noen ingredienser enda...</v-card-text>
  </v-card>
    <div v-else>
    <v-divider></v-divider>
    <v-data-table :headers="ingredientTitlesV2" :items="ingredientsV2">
      <!--<template v-slot:body="">
        <tr v-for="prop in props" :key="props.ingredientName">
          <td v-for="nutrient in prop.nutrients">
            
          </td>
        </tr>-->
        <!--<tbody>
          <tr
            v-for="item in ingredients"
            :key="item.ingredientName"
          >
            <td>{{item.ingredientName}}</td>
            <td v-for="nutrient in item.nutrients" v-bind:key="item.ingredientName+nutrient.title">{{ nutrient.value/100*item.measurement.multiplier*item.measurement.value }}</td>
          </tr>
        </tbody>
      </template>-->
    </v-data-table>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Totalt
            </th>
            <th class="text-left" v-for="title in ingredientTitles" v-bind:key="title">
              {{title}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sum</td>
            <td v-for="nutrient in nutrientsTotal" v-bind:key="sum+nutrient.title">{{ nutrient.values }}<span v-if="nutrient.isUnknown">*</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
  </v-container>
</template>
<style>
  table > tbody > tr > td:nth-child(1), 
  table > thead > tr > th:nth-child(1) {
    position: sticky !important; 
    position: -webkit-sticky !important; 
    left: 0;
    color: rgba(0,0,0,1);
    z-index: 8;
    
    border-top: 0 !important;
    border-right: 3px solid #d4d4d4;
    border-radius: 0 !important;
    background: white;
  }
  table > thead > tr > th:nth-child(1) {
    border-bottom: 0 !important;
  }
  table > thead > tr > th,
  table > tbody > tr > td,
  table > tbody > tr > td:nth-child(1) {
    border-bottom: 1px solid #d4d4d4;
  }

  table > thead > tr > td:hover,
  table > thead > tr > th:hover {
    border-bottom: 1px solid #d4d4d4;
    border-radius: 0;
  }
  table > thead > tr > th:nth-child(1) {
    z-index: 9;
  }
</style>
<script type="">
  
  export default {
    data:()=> ({
      ingredients:[],
      ingredientsV2:[],
      ingredientTitles:[],
      ingredientTitlesV2:[],
      nutrientsTotal:[]
    }),
  metaInfo: {
      // override the parent template and just use the above title only
      title: 'Ingrediens Kalkyler',
      // all titles will be injected into this template
      titleTemplate: '%s | Brukmat'
    },
    watch: {
      '$route': 'getIngredient'
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.getIngredients();
    },
    methods: {
      strip(number) {
        let num = Number(number).toFixed(2);
        if (num.match(/\./)) {
          num = num.replace(/\.?0+$/, '');
        }
        return Number(num);
      },
      getIngredients: async function(){
        let ingredientsSelected = this.$store.getters.searchText;
        this.ingredients = ingredientsSelected;
        let dataScheme = await this.$scheme;
        let ingredients = ingredientsSelected.map(ing=>{
          ing.nutrients = ing.nutrients.map((nutrient)=>{
            if(dataScheme[nutrient.title]){
              return {
                color:dataScheme[nutrient.title].color || 'grey',
                title:dataScheme[nutrient.title].title || nutrient.title,
                shortTitle: nutrient.title,
                value:nutrient.value/100*ing.measurement.multiplier*ing.measurement.value,
                multiplier:dataScheme[nutrient.title].multiplier || 1,
                measurement:dataScheme[nutrient.title].measurement,
                category:dataScheme[nutrient.title].category || 'Ukategorisert',
                link:dataScheme[nutrient.title].link
              }
            } else {
              return {
                color:'grey',
                title:nutrient.title,
                shortTitle:nutrient.title,
                value:nutrient.value/100*ing.measurement.multiplier*ing.measurement.value,
                multiplier:1,
                category:"Ukategorisert"
              }
            }
          });
          return ing;
        });
        this.ingredientTitles = ingredientsSelected[0].nutrients.map(nute=>{
          return nute.title;
        });
        
        let titlesV2 = ingredients[0].nutrients.map(nute=>{
          return {
            text:nute.title,
            value:nute.shortTitle,
            width:"100px"
          }
        });
        let nameField = {
          text:"Navn",
          value:"name",
          width:"200px",
          fixed:true
        }
        this.ingredientTitlesV2 = [nameField, ...titlesV2];

        let ingredientsV2 = ingredients.map(ing=>{
          let obj = ing.nutrients.reduce((acc, nute)=>{
            acc[nute.shortTitle] = this.strip(nute.value);
            return acc;
          },{});
          obj.name = ing.ingredientName;
          return obj;
        });
        this.ingredientsV2 = ingredientsV2;

        // Add ingredients together
        let values = [];
        for(let i in ingredientsSelected[0].nutrients){
          var isOneUnknown = false;
          let addedValues = ingredientsSelected.reduce((acc, ingredient) => {
            if(ingredient.nutrients[i].value == -1){
              isOneUnknown = true;
              return acc + 0;
            } else {
              return acc + ingredient.nutrients[i].value;
            }
          }, 0);
          values.push({values:this.strip(addedValues), isUnknown:isOneUnknown});
        }
        console.log(values);
        this.nutrientsTotal = values;
        
      }
    }
  }
</script>